import {
  FormEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import styles from './Form.module.scss';
import { ApiError, asApiError } from '../../services/ApiError';
import { classNames } from '../../utils/classNames';
import { Button } from './Button';

export interface Props {
  children: ReactNode;
  onSubmit: () => void | Promise<void>;
  submitLabel?: string;
  submitDisabled?: boolean;
  altActionLabel?: string;
  onAltAction?: () => void | Promise<void>;
  fullWidth?: boolean;
}

export const Form = ({
  children,
  onSubmit,
  submitLabel,
  submitDisabled,
  altActionLabel,
  onAltAction,
  fullWidth,
}: Props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();

  const submitForm = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setApiError(undefined);
      setSubmitLoading(true);

      try {
        await onSubmit();
      } catch (e) {
        setApiError(asApiError(e));
      } finally {
        setSubmitLoading(false);
      }
    },
    [onSubmit]
  );

  return (
    <form
      onSubmit={submitForm}
      className={classNames(styles.form, fullWidth && styles.fullWidth)}
    >
      {children}
      <div className={styles.actions}>
        {altActionLabel && (
          <Button kind="link" text={altActionLabel} onClick={onAltAction} />
        )}
        <div />
        {submitLabel && (
          <Button
            type="submit"
            text={submitLabel}
            loading={submitLoading}
            disabled={submitDisabled}
          />
        )}
      </div>
      {apiError && (
        <div className={styles.errorMessage}>
          {apiError.message}
          {apiError.errors && (
            <ul>
              {apiError.errors.map((e, i) => (
                <li key={i}>{Object.values(e.constraints)[0]}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </form>
  );
};

export interface FormFieldProps {
  label: string;
  children: ReactElement;
}

export const FormField = ({ label, children }: FormFieldProps) => (
  <label
    className={classNames(
      styles.field,
      label && children.props.required && styles.required
    )}
  >
    <span className={styles.label}>{label}</span>
    {children}
  </label>
);

export interface FieldGroupProps {
  children: ReactNode;
}

export const FieldGroup = ({ children }: FieldGroupProps) => (
  <div className={styles.fieldGroup}>{children}</div>
);

export interface FormSectionProps {
  text: string;
}

export const FormSection = ({ text }: FormSectionProps) => (
  <div className={styles.section}>{text}</div>
);
