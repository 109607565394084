import styles from './Stepper.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { classNames } from '../utils/classNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';

export interface Props {
  steps: Step[];
  step: number;
}

export interface Step {
  icon: IconProp;
}

export const Stepper = ({ steps, step }: Props) => {
  return (
    <div className={styles.stepper}>
      {steps.map((s, i) => (
        <Fragment key={i}>
          <div className={classNames(styles.step, step > i && styles.done)}>
            <FontAwesomeIcon icon={step > i ? 'check' : s.icon} />
          </div>
          {i < steps.length - 1 && (
            <div className={classNames(styles.bar, step > i && styles.done)} />
          )}
        </Fragment>
      ))}
    </div>
  );
};
