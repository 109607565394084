import { useState } from 'react';
import { Menu } from './Menu';
import { TopBar } from './TopBar';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="layout">
      <Menu visible={showMenu} onClose={() => setShowMenu(false)} />
      <div className="main-wrapper">
        <TopBar
          showMenu={showMenu}
          onToggleMenu={() => setShowMenu((prevState) => !prevState)}
        />
        <Outlet />
      </div>
    </div>
  );
};
