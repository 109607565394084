import { useApi } from '../hooks/useApi';
import { useRequiredIntParam } from '../hooks/useRequiredIntParam';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/date';
import { useCallback, useEffect, useState } from 'react';
import { useFormValue } from '../hooks/useFormValue';
import { PageLoading } from '../components/layout/PageLoading';
import { Dividend, DividendDetails, UpdateDividend } from '../model/Dividends';
import { EditableColumns } from '../components/form/editable/EditableColumns';
import { EditableForm } from '../components/form/editable/EditableForm';
import { Button } from '../components/form/Button';
import { ActionsBlock } from '../components/ActionsBlock';
import { Table } from '../components/table/Table';
import { ParticipantLink } from '../components/ParticipantLink';
import { formatPercentWhole } from '../utils/percent';
import { formatEuro } from '../utils/currency';
import { StatCard, StatCards } from '../components/StatCard';
import { PageHeader } from '../components/layout/PageHeader';
import { DownloadButton } from '../components/form/DownloadButton';

export const DividendPage = () => {
  const api = useApi();
  const dividendId = useRequiredIntParam('dividendId');

  const [dividend, setDividend] = useState<Dividend>();
  const [updatedDividend, setUpdatedField, setUpdatedDividend] =
    useFormValue<UpdateDividend>(() => ({
      date: '',
      amount: 0,
      notes: '',
      taxPercent: 0,
    }));
  const [details, setDetails] = useState<DividendDetails>();

  useEffect(() => {
    api.dividends.getDividend(dividendId).then(setDividend);
    api.dividends.getDividendDetails(dividendId).then(setDetails);
  }, [api, dividendId]);

  const startEditing = useCallback(() => {
    if (!dividend) {
      return;
    }

    setUpdatedDividend({ ...dividend });
  }, [dividend, setUpdatedDividend]);

  const saveEdits = useCallback(() => {
    return api.dividends
      .updateDividend(dividendId, updatedDividend)
      .then(setDividend);
  }, [api, dividendId, updatedDividend]);

  if (!dividend) {
    return <PageLoading />;
  }

  return (
    <main>
      <PageHeader
        title={
          <>
            <Link to="/dividends">Dividend</Link> / {formatDate(dividend.date)}
          </>
        }
      />
      <section className="info-columns">
        <EditableForm onSubmit={saveEdits} onEdit={startEditing}>
          {({ editing }) => (
            <EditableColumns
              currentValue={dividend}
              value={updatedDividend}
              editing={editing}
              setUpdatedField={setUpdatedField}
              rows={[
                {
                  name: 'Peildatum',
                  key: 'date',
                  icon: 'calendar-day',
                  required: true,
                  type: 'date',
                  editable: !dividend.locked,
                },
                {
                  name: 'Waarde',
                  key: 'amount',
                  icon: 'coins',
                  required: true,
                  type: 'euro-whole',
                  editable: !dividend.locked,
                },
                {
                  name: 'Belasting',
                  key: 'taxPercent',
                  icon: 'percent',
                  type: 'percent',
                  editable: false,
                },
                {
                  name: 'Notities',
                  key: 'notes',
                  icon: 'clipboard-list',
                  type: 'textarea',
                },
              ]}
            />
          )}
        </EditableForm>
        <ActionsBlock>
          {!dividend.locked && (
            <Button
              text="Vaststellen"
              icon="check"
              kind="primary"
              onClick={() =>
                api.dividends
                  .lockDividend(dividendId)
                  .then(() => api.dividends.getDividend(dividendId))
                  .then(setDividend)
              }
            />
          )}
          <DownloadButton
            href={`/api/dividends/${dividendId}/csv`}
            download={`dividend_${dividend.date}.csv`}
            icon="table"
            text="Download CSV"
          />
          <DownloadButton
            href={`/api/dividends/${dividendId}/pdf`}
            download={`dividend_${dividend.date}.pdf`}
            icon="file-pdf"
            text="Download PDF"
          />
        </ActionsBlock>
      </section>
      <section>
        {details && (
          <StatCards>
            <StatCard
              value={details.participantDividends.length}
              label="Deelnemers"
            />
            <StatCard value={details.totalCertificates} label="Certificaten" />
            <StatCard
              value={formatEuro(details.totalGrossPayout)}
              label="Totaal bruto"
            />
            <StatCard
              value={formatEuro(details.totalTaxes)}
              label="Totale belasting"
            />
            <StatCard
              value={formatEuro(details.totalNetPayout)}
              label="Totaal netto"
            />
          </StatCards>
        )}
      </section>
      <section>
        <Table
          data={details?.participantDividends}
          columns={[
            {
              name: 'Deelnemer',
              valueFn: (d) => <ParticipantLink participant={d.participant} />,
            },
            {
              name: 'Certificaten',
              valueFn: (d) => d.certificateCount,
            },
            {
              name: 'Bruto dividend',
              valueFn: (d) => formatEuro(d.grossPayout),
            },
            {
              name: `Belasting (${formatPercentWhole(dividend.taxPercent)})`,
              valueFn: (d) => formatEuro(d.taxes),
            },
            {
              name: 'Netto dividend',
              valueFn: (d) => formatEuro(d.netPayout),
            },
          ]}
        />
      </section>
    </main>
  );
};
