import { Drawer } from '../Drawer';
import { CloseProps } from '../Modal';
import { Transaction, transactionTypeNames } from '../../model/Transaction';
import { DocumentsTable } from '../tables/DocumentsTable';
import { SelectFileButton } from '../../SelectFileButton';
import { useBlobs } from '../../hooks/useBlobs';
import { ItemRows } from '../ItemRows';
import { formatDate } from '../../utils/date';
import { formatEuro } from '../../utils/currency';
import { ParticipantLink } from '../ParticipantLink';

export interface Props extends CloseProps {
  transaction: Transaction;
}

export const TransactionDrawer = ({ onClose, transaction }: Props) => {
  const [documents, uploadDocument, refreshDocuments] = useBlobs(
    'transaction',
    transaction.id
  );

  return (
    <Drawer title="Transactie" onClose={onClose}>
      <ItemRows
        data={[
          {
            key: 'Datum',
            value: formatDate(transaction.date),
            icon: 'calendar-day',
          },
          {
            key: 'Deelnemer',
            value: <ParticipantLink participant={transaction.participant} />,
            icon: 'user',
          },
          {
            key: 'Type',
            value: transactionTypeNames[transaction.type],
            icon: 'certificate',
          },
          {
            key: 'Aantal',
            value: transaction.certificates.length,
            icon: 'hashtag',
          },
          {
            key: 'Prijs',
            value:
              transaction.price != null ? formatEuro(transaction.price) : '-',
            icon: 'euro',
          },
        ]}
      />

      <div className="title">
        <h3>Documenten</h3>
        <SelectFileButton onSelectFile={uploadDocument} />
      </div>
      <DocumentsTable blobs={documents} onRefresh={refreshDocuments} />

      <h3>Certificaten</h3>
      <p>{transaction.certificates.map((c) => c.fullName).join(', ')}</p>
    </Drawer>
  );
};
