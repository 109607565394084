import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { AssignRole, Role, RoleValue } from '../model/Role';

export class RolesService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public getCurrentRoles(): Promise<RoleValue[]> {
    return this.api.jsonGet('/api/roles/current');
  }

  public listRoles(): Promise<Role[]> {
    return this.api.jsonGet('/api/roles');
  }

  public assignRole(assignment: AssignRole): Promise<void> {
    return this.api.jsonPost('/api/roles', assignment);
  }

  public removeRole(id: number): Promise<void> {
    return this.api.jsonDelete(`/api/roles/${id}`);
  }
}
