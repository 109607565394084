import { User } from './User';
import { Base } from './Base';
import { Option } from '../components/form/Select';

export interface Role extends Base {
  value: RoleValue;
  user: User;
}

export enum RoleValue {
  admin = 'admin',
}

export const RoleNames: Record<RoleValue, string> = {
  [RoleValue.admin]: 'Beheerder',
};

export interface AssignRole {
  email: string;
  role: RoleValue;
}

export const RoleOptions: Option<RoleValue>[] = [
  {
    value: RoleValue.admin,
    displayValue: RoleNames[RoleValue.admin],
  },
];
