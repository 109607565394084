import { CloseSubmitProps, Modal } from '../Modal';
import { useFormValue } from '../../hooks/useFormValue';
import { Form, FormField } from '../form/Form';
import { Input } from '../form/Input';
import { AssignRole, RoleOptions, RoleValue } from '../../model/Role';
import { Select } from '../form/Select';
import { useApi } from '../../hooks/useApi';

export const CreateRoleAssignmentModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps) => {
  const api = useApi();
  const [assignment, setField] = useFormValue<AssignRole>({
    email: '',
    role: RoleValue.admin,
  });

  return (
    <Modal title="Rol toewijzen" onClose={onClose}>
      <Form
        submitLabel="Opslaan"
        onSubmit={() =>
          api.roles.assignRole(assignment).then(() => {
            onSubmit();
            onClose();
          })
        }
        fullWidth
      >
        <FormField label="E-mailadres">
          <Input
            value={assignment.email}
            onChange={setField('email')}
            required
            type="email"
          />
        </FormField>
        <FormField label="Rol">
          <Select
            value={assignment.email}
            onChange={setField('role')}
            required
            options={RoleOptions}
          />
        </FormField>
      </Form>
    </Modal>
  );
};
