import { formatDate } from '../utils/date';
import situatie1 from '../images/income-tax/situatie-1.png';
import situatie2 from '../images/income-tax/situatie-2.png';
import beleggingen from '../images/income-tax/beleggingen.png';
import dividendbelasting from '../images/income-tax/dividendbelasting.png';
import { formatEuro, formatWholeEuros } from '../utils/currency';
import { ItemRows, Row } from './ItemRows';
import { useEffect, useState } from 'react';
import { ParticipantIncomeTax } from '../model/ParticipantIncomeTax';
import { useApi } from '../hooks/useApi';
import { PageLoading } from './layout/PageLoading';
import styles from './IncomeTaxSection.module.scss';
import { useAssertCurrent } from '../hooks/useCurrent';

export interface Props {
  participantId: number;
}

export const IncomeTaxSection = ({ participantId }: Props) => {
  const api = useApi();
  const [info, setInfo] = useState<ParticipantIncomeTax>();
  const { tenant } = useAssertCurrent();

  useEffect(() => {
    api.incomeTax.getParticipantIncomeTax(participantId).then(setInfo);
  }, [api.incomeTax, participantId]);

  if (!info) {
    return <PageLoading />;
  }

  const hadCerts = info.certificateCount > 0;

  return (
    <section className={styles.incomeTaxSection}>
      <p>
        Hier vind je alles wat je nodig hebt voor je aangifte
        inkomstenbelasting. Het jaaroverzicht is een kort overzicht van je
        belegging, voor het invullen kan je de antwoorden van de invulhulp
        overnemen.
      </p>

      <h2>Jaaroverzicht</h2>
      <ItemRows
        data={[
          {
            icon: 'certificate',
            key: `Certificaten op ${formatDate(info.date)}`,
            value: info.certificateCount,
          },
          {
            icon: 'coins',
            key: `Totale waarde op ${formatDate(info.date)}`,
            value: formatEuro(info.totalValue),
          },
          {
            icon: 'percent',
            key: 'Uitgekeerd dividend (netto)',
            value: formatEuro(info.netDividends),
          },
          {
            icon: 'hand-holding-dollar',
            key: 'Ingehouden dividendbelasting',
            value: formatEuro(info.dividendTax),
          },
        ]}
      />

      <h2>Invulhulp</h2>
      {hadCerts || info.dividendTaxRoundedUp > 0 ? (
        <>
          <ItemRows
            noIcons
            data={
              [
                {
                  key: 'Omschrijving',
                  value: `${tenant.name} certificaten`,
                },
                {
                  key: 'Nummer',
                  value: '1 (mag je zelf verzinnen)',
                },
                {
                  key: `Had u deze belegging op ${formatDate(info.date)}?`,
                  value: hadCerts ? 'Ja' : 'Nee',
                },
                hadCerts && {
                  key: `Waarde op ${formatDate(info.date)}`,
                  value: formatWholeEuros(info.totalValueRoundedDown),
                },
                {
                  key: 'Was deze belegging alleen van jou?',
                  value: 'Ja',
                },
                {
                  key: 'Ingehouden Nederlandse dividendbelasting',
                  value: formatWholeEuros(info.dividendTaxRoundedUp),
                },
                {
                  key: 'Is er buitenlandse belasting ingehouden?',
                  value: 'Nee',
                },
              ].filter((r) => !!r) as Row[]
            }
          />
          <h3>Ingevuld voorbeeld</h3>
          <img
            src={hadCerts ? situatie1 : situatie2}
            alt="Een voorbeeld van een ingevulde belegging"
          />

          <h3>Ik krijg geen scherm om de belegging in te vullen</h3>
          <p>
            Bij het scherm "Bankrekeningen en bezittingen" vink je dan
            "Beleggingen" aan, en selecteer je "Ja" bij de vraag "Is aan u
            dividend uitgekeerd waarover dividendbelasting is ingehouden?".
          </p>
          <img src={beleggingen} alt="De checkbox voor beleggingen" />
          <img
            src={dividendbelasting}
            alt="De checkbox voor dividendbelasting"
          />
        </>
      ) : (
        <p>
          Je had geen certificaten in bezit op {formatDate(info.date)}, en er is
          geen dividendbelasting ingehouden, dus je hoeft niks aan te geven!
        </p>
      )}
    </section>
  );
};
