import { useApi } from '../../hooks/useApi';
import React, { useCallback, useEffect, useState } from 'react';
import { Role, RoleNames } from '../../model/Role';
import { Table } from '../../components/table/Table';
import { PageLoading } from '../../components/layout/PageLoading';
import { CreateRoleAssignmentModal } from '../../components/modals/CreateRoleAssignmentModal';
import { Button } from '../../components/form/Button';
import styles from './RoleSettings.module.scss';

export const RoleSettings = () => {
  const api = useApi();
  const [roles, setRoles] = useState<Role[]>();
  const [createRoleModal, setCreateRoleModal] = useState(false);

  const refresh = useCallback(() => {
    api.roles.listRoles().then(setRoles);
  }, [api.roles]);

  useEffect(refresh, [refresh]);

  if (!roles) {
    return <PageLoading />;
  }

  return (
    <>
      <div className={styles.roleSettingsActions}>
        <Button
          text="Rol toewijzen"
          icon="plus"
          onClick={() => setCreateRoleModal(true)}
          kind="primary"
        />
      </div>
      <Table
        data={roles}
        columns={[
          {
            name: 'Naam',
            valueFn: (r) => r.user.name,
          },
          {
            name: 'E-mailadres',
            valueFn: (r) => r.user.email,
          },
          {
            name: 'Rol',
            valueFn: (r) => RoleNames[r.value],
          },
        ]}
        actions={(r) => (
          <Button
            icon="trash"
            onClick={() => api.roles.removeRole(r.id).then(() => refresh())}
            kind="basic"
          />
        )}
      />
      {createRoleModal && (
        <CreateRoleAssignmentModal
          onSubmit={() => refresh()}
          onClose={() => setCreateRoleModal(false)}
        />
      )}
    </>
  );
};
