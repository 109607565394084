import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Drawer.module.scss';
import { Button } from './form/Button';
import { useClickOutside } from '../hooks/useClickOutside';
import { classNames } from '../utils/classNames';

export interface Props {
  children: ReactNode;
  title: string;
  onClose: () => void;
}

export const Drawer = ({ children, title, onClose }: Props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Request an animation frame to set the open state, to give the browser a chance to paint first.
    // That way we ensure the CSS transition will trigger properly.
    const r = requestAnimationFrame(() => setOpen(true));
    return () => cancelAnimationFrame(r);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
    setTimeout(onClose, 200);
  }, [onClose]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, close);

  return (
    <div className={classNames(styles.drawer, open && styles.open)}>
      <div className={styles.content} ref={ref}>
        <div className={styles.title}>
          <h2>{title}</h2>
          <Button onClick={close} icon="times" />
        </div>
        {children}
      </div>
    </div>
  );
};
