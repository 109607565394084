import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { InviteParticipant, Participant } from '../../model/Participant';
import { Input } from '../form/Input';
import { Form, FormField } from '../form/Form';

export const CreateParticipantModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<Participant>) => {
  const api = useApi();
  const [participant, setField] = useFormValue<InviteParticipant>({
    email: '',
  });

  return (
    <Modal title="Nieuwe deelnemer" onClose={onClose}>
      <Form
        submitLabel="Opslaan"
        onSubmit={() =>
          api.participants.inviteParticipant(participant).then((m) => {
            onSubmit(m);
            onClose();
          })
        }
        fullWidth
      >
        <FormField label="E-mailadres">
          <Input
            value={participant.email}
            onChange={setField('email')}
            required
            type="email"
          />
        </FormField>
      </Form>
    </Modal>
  );
};
