import { Button, Props as ButtonProps } from './Button';
import { useApi } from '../../hooks/useApi';

export interface Props extends Omit<ButtonProps, 'onClick'> {
  href: string;
  download: string;
}

export const DownloadButton = ({ href, download, ...props }: Props) => {
  const api = useApi();

  return (
    <Button
      {...props}
      onClick={async () => {
        await api.client.refreshTokens();
        const a = document.createElement('a');
        a.href = href;
        a.download = download;
        a.click();
        a.remove();
      }}
    />
  );
};
