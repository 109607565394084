import { useApi } from '../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { PageLoading } from '../components/layout/PageLoading';
import { usePagedData } from '../hooks/usePagedData';
import { useRequiredIntParam } from '../hooks/useRequiredIntParam';
import { ParticipantSummary, UpdateParticipant } from '../model/Participant';
import { CreateTransactionsModal } from '../components/modals/CreateTransactionsModal';
import { Button } from '../components/form/Button';
import { Link } from 'react-router-dom';
import { CertificatesTable } from '../components/tables/CertificatesTable';
import { TransactionsTable } from '../components/tables/TransactionsTable';
import { useFormValue } from '../hooks/useFormValue';
import { EditableForm } from '../components/form/editable/EditableForm';
import { StatCard, StatCards } from '../components/StatCard';
import { ActionsBlock } from '../components/ActionsBlock';
import { Tabs } from '../components/Tabs';
import { EditableColumns } from '../components/form/editable/EditableColumns';
import { PageHeader } from '../components/layout/PageHeader';
import { useBlobs } from '../hooks/useBlobs';
import { DocumentsTable } from '../components/tables/DocumentsTable';
import { useAssertCurrent } from '../hooks/useCurrent';
import { RoleValue } from '../model/Role';
import { SelectFileButton } from '../SelectFileButton';
import { IncomeTaxSection } from '../components/IncomeTaxSection';

export const ParticipantPage = () => {
  const api = useApi();
  const participantId = useRequiredIntParam('participantId');
  const { roles } = useAssertCurrent();

  const [participant, setParticipant] = useState<ParticipantSummary>();
  const [updatedParticipant, setUpdatedField, setUpdatedParticipant] =
    useFormValue<UpdateParticipant>({
      notes: '',
    });

  const transactions = usePagedData(
    api.transactions.listTransactions,
    {
      participantId,
    },
    'transactions'
  );
  const certificates = usePagedData(
    api.certificates.listCertificates,
    {
      participantId,
    },
    'certificates'
  );
  const [documents, uploadDocument, refreshDocuments] = useBlobs(
    'participant',
    participantId
  );

  const [newTransactionsModal, setNewTransactionsModal] = useState(false);

  useEffect(() => {
    api.participants.getParticipant(participantId).then(setParticipant);
  }, [api, participantId]);

  const submitTransactions = useCallback(() => {
    transactions.reloadPage();
    certificates.reloadPage();
    api.participants.getParticipant(participantId).then(setParticipant);
  }, [api, certificates, participantId, transactions]);

  const startEditing = useCallback(() => {
    if (!participant) {
      return;
    }

    setUpdatedParticipant({ ...participant });
  }, [participant, setUpdatedParticipant]);

  const refreshParticipant = useCallback(
    () => api.participants.getParticipant(participantId).then(setParticipant),
    [api, participantId]
  );

  const saveEdits = useCallback(async () => {
    await api.participants.updateParticipant(participantId, updatedParticipant);
    await refreshParticipant();
  }, [api, participantId, refreshParticipant, updatedParticipant]);

  if (!participant) {
    return <PageLoading />;
  }

  return (
    <>
      <main>
        <PageHeader
          title={
            <>
              <Link to="/participants">Deelnemers</Link> /{' '}
              {participant.user.name}
              {participant.archived && <em> (gearchiveerd)</em>}
            </>
          }
        />
        <div className="info-columns">
          <EditableForm
            onSubmit={saveEdits}
            onEdit={startEditing}
            canEdit={roles.includes(RoleValue.admin)}
          >
            {({ editing }) => (
              <EditableColumns
                currentValue={participant}
                value={updatedParticipant}
                editing={editing}
                setUpdatedField={setUpdatedField}
                rows={[
                  {
                    name: 'Startdatum',
                    key: 'startDate',
                    icon: 'calendar-day',
                    type: 'date',
                  },
                  {
                    name: 'Einddatum',
                    key: 'endDate',
                    icon: 'calendar-xmark',
                    type: 'date',
                  },
                  {
                    name: 'Notities',
                    key: 'notes',
                    icon: 'clipboard-list',
                    type: 'textarea',
                  },
                ]}
              />
            )}
          </EditableForm>
          {roles.includes(RoleValue.admin) && (
            <ActionsBlock>
              {participant.archived ? (
                <Button
                  text="Dearchiveren"
                  icon="boxes-packing"
                  onClick={() =>
                    api.participants
                      .unarchiveParticipant(participant.id)
                      .then(refreshParticipant)
                  }
                />
              ) : (
                <>
                  <Button
                    text="Nieuwe transacties"
                    icon="right-left"
                    onClick={() => setNewTransactionsModal(true)}
                    kind="primary"
                  />
                  <SelectFileButton onSelectFile={uploadDocument} />
                  <Button
                    text="Archiveren"
                    icon="archive"
                    onClick={() =>
                      api.participants
                        .archiveParticipant(participant.id)
                        .then(refreshParticipant)
                    }
                  />
                </>
              )}
            </ActionsBlock>
          )}
          <StatCards>
            <StatCard
              value={participant.certificatesCount}
              label="Certificaten"
            />
          </StatCards>
        </div>
        <section>
          <Tabs
            tabs={[
              {
                name: 'Transacties',
                element: (
                  <TransactionsTable
                    transactions={transactions}
                    participantId={participantId}
                  />
                ),
              },
              {
                name: 'Certificaten',
                element: (
                  <CertificatesTable
                    certificates={certificates}
                    participantId={participantId}
                  />
                ),
              },
              {
                name: 'Documenten',
                element: (
                  <DocumentsTable
                    blobs={documents}
                    onRefresh={refreshDocuments}
                  />
                ),
              },
              {
                name: 'Belastingaangifte',
                element: <IncomeTaxSection participantId={participantId} />,
              },
            ]}
          />
        </section>
      </main>
      {newTransactionsModal && (
        <CreateTransactionsModal
          onClose={() => setNewTransactionsModal(false)}
          onSubmit={submitTransactions}
          participantId={participantId}
        />
      )}
    </>
  );
};
