import { CertificatesTable } from '../components/tables/CertificatesTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { useAssertCurrent } from '../hooks/useCurrent';
import { RoleValue } from '../model/Role';
import { PageHeader } from '../components/layout/PageHeader';

export const CertificatesPage = () => {
  const api = useApi();
  const { roles, participant } = useAssertCurrent();
  const certificates = usePagedData(api.certificates.listCertificates);

  return (
    <main>
      <PageHeader title="Certificaten" />
      <CertificatesTable
        certificates={certificates}
        participantId={
          roles.includes(RoleValue.admin) ? undefined : participant?.id
        }
      />
    </main>
  );
};
