import { Table } from '../table/Table';
import { BlobList } from '../../model/Blob';
import { useApi } from '../../hooks/useApi';
import { Button } from '../form/Button';

export interface Props {
  blobs?: BlobList;
  onRefresh: () => void;
}

export const DocumentsTable = ({ blobs, onRefresh }: Props) => {
  const api = useApi();

  return (
    <Table
      data={blobs?.blobs}
      loading={!blobs}
      onClickRow={async (r) => {
        await api.client.refreshTokens();
        const a = document.createElement('a');
        a.href = `/api/blob/${blobs?.type}/${blobs?.id}/${encodeURIComponent(
          r.name
        )}`;
        a.download = r.name;
        a.click();
        a.remove();
      }}
      columns={[
        {
          name: 'Naam',
          valueFn: (b) => b.name,
        },
      ]}
      actions={(b) => (
        <Button
          icon="trash"
          onClick={(e) => {
            e.stopPropagation();
            if (blobs) {
              return api.blob
                .remove(blobs.type, blobs.id, b.name)
                .then(onRefresh);
            }
          }}
        />
      )}
    />
  );
};
