import { ApiError, asApiErrorResponse } from './ApiError';
import { Paths } from '../router';

type QueryParams = Record<string, string | number | boolean>;

export interface Options {
  // Whether to throw when a response status is 401.
  // By default this will redirect to the login page.
  throwOnUnauthorized?: boolean;
}

export class ApiClient {
  private readonly defaultHeaders: Record<string, string>;

  /**
   * A promise that is set while refreshing tokens.
   * Will resolve with a boolean, indicating whether the refresh was successful.
   */
  private refreshPromise: Promise<boolean> | undefined;

  public constructor(private readonly defaultOptions: Options = {}) {
    this.defaultHeaders = {
      Accept: 'application/json',
    };
  }

  private async handleResponse<T>(
    response: Response,
    options: Options | undefined
  ): Promise<T> {
    const responseBody =
      response.status === 204 ? undefined : await response.json();

    const resolvedOptions = { ...this.defaultOptions, ...options };

    // If we get an unauthorised response, check if we should redirect to the login page.
    if (response.status === 401 && !resolvedOptions.throwOnUnauthorized) {
      window.location.href = `${Paths.login}?redirect=${encodeURIComponent(
        `${window.location.pathname}${window.location.search}${window.location.hash}`
      )}`;
    }

    if (response.status >= 400) {
      const errBody = asApiErrorResponse(responseBody);
      throw new ApiError(response.status, errBody.message, errBody.errors);
    }

    return responseBody;
  }

  private static getUrl(path: string, params?: QueryParams): string {
    const stringParams: Record<string, string> = {};
    if (params) {
      Object.entries(params).forEach(([k, v]) => {
        if (v != null) {
          stringParams[k] = `${v}`;
        }
      });
    }

    const paramString = params ? `?${new URLSearchParams(stringParams)}` : '';
    return `${path}${paramString}`;
  }

  public async refreshTokens(): Promise<boolean> {
    // If we are already refreshing, await that.
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    // Make the refresh call, and store it.
    this.refreshPromise = fetch('/auth/refresh', { method: 'POST' }).then(
      (response) => response.ok
    );

    // Await the refresh promise, and unset it when done.
    const result = await this.refreshPromise;
    this.refreshPromise = undefined;
    return result;
  }

  private async request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    path: string,
    body?: unknown,
    params?: QueryParams,
    options?: Options
  ): Promise<T> {
    const headers = { ...this.defaultHeaders };
    if (body && !(body instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    const serializedBody =
      body == null || body instanceof FormData ? body : JSON.stringify(body);
    const doFetch = () =>
      fetch(ApiClient.getUrl(path, params), {
        method,
        headers,
        body: serializedBody,
      });

    let response = await doFetch();

    // If we get an unauthorised response, refresh the tokens and retry.
    if (response.status === 401 && (await this.refreshTokens())) {
      response = await doFetch();
    }

    return this.handleResponse(response, options);
  }

  public jsonGet<T>(
    path: string,
    params?: QueryParams,
    options?: Options
  ): Promise<T> {
    return this.request('GET', path, undefined, params, options);
  }

  public jsonPost<T, S>(
    path: string,
    body: T,
    params?: QueryParams,
    options?: Options
  ): Promise<S> {
    return this.request<S>('POST', path, body, params, options);
  }

  public jsonPut<T, S>(
    path: string,
    body: T,
    params?: QueryParams,
    options?: Options
  ): Promise<S> {
    return this.request<S>('PUT', path, body, params, options);
  }

  public jsonDelete<S>(
    path: string,
    params?: QueryParams,
    options?: Options
  ): Promise<S> {
    return this.request<S>('DELETE', path, undefined, params, options);
  }

  public async formPost<S>(
    path: string,
    body: Record<string, string | Blob>,
    params?: QueryParams,
    options?: Options
  ): Promise<S> {
    const formBody = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formBody.set(key, body[key]);
      }
    }

    return this.request<S>('POST', path, formBody, params, options);
  }
}
