import { useApi } from '../hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import {
  CreateTradeRoundRegistration,
  TradeRoundStatus,
} from '../model/TradeRound';
import { PageLoading } from '../components/layout/PageLoading';
import { formatDate } from '../utils/date';
import { TradeRoundRegistrationForm } from '../components/TradeRoundRegistrationForm';
import { transactionTypeNames } from '../model/Transaction';
import { PageHeader } from '../components/layout/PageHeader';

export const CurrentTradeRoundPage = () => {
  const api = useApi();
  const [status, setStatus] = useState<TradeRoundStatus>();
  const [certPrice, setCertPrice] = useState<number>();

  useEffect(() => {
    api.trading.getTradeRoundStatus().then(setStatus);
    api.stats
      .getCertPrice()
      .then(setCertPrice)
      .catch((e) => {
        console.error(`Could not get cert price: ${e}`);
        setCertPrice(0);
      });
  }, [api]);

  const registerAndRefresh = useCallback(
    async (reg: CreateTradeRoundRegistration) => {
      await api.trading.createTradeRoundRegistration(reg);
      api.trading.getTradeRoundStatus().then(setStatus);
    },
    [api]
  );

  if (!status || certPrice == null) {
    return <PageLoading />;
  }

  return (
    <main>
      <PageHeader title="Handelsronde" />
      {!status.current && status.upcoming && (
        <p>
          De volgende handelsronde begint op{' '}
          {formatDate(status.upcoming.startDate)}.
        </p>
      )}
      {!status.current && !status.upcoming && (
        <p>Er is op het moment geen handelsronde actief of gepland.</p>
      )}
      {status.current && (
        <section>
          <p>
            De huidige handelsronde loopt van{' '}
            {formatDate(status.current.startDate)} t/m{' '}
            {formatDate(status.current.endDate)}.
          </p>
          <h2>Inschrijven</h2>
          {status.registration ? (
            <p>
              Je hebt je ingeschreven voor de handelsronde voor de{' '}
              {transactionTypeNames[status.registration.type].toLowerCase()} van{' '}
              {status.registration.requestedAmount} certificaten.
            </p>
          ) : (
            <TradeRoundRegistrationForm
              certPrice={certPrice}
              onSubmit={registerAndRefresh}
            />
          )}
        </section>
      )}
    </main>
  );
};
