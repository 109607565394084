import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useApi } from './useApi';
import { ApiError } from '../services/ApiError';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../router';
import { CurrentAuth } from '../services/AuthService';
import { RoleValue } from '../model/Role';
import { CurrentParticipant } from '../model/Participant';

export interface Current extends CurrentAuth, CurrentParticipant {
  roles: RoleValue[];
}

const CurrentContext = React.createContext<{ current?: Current } | undefined>(
  undefined
);

export interface Props {
  children: ReactNode;
  loader: JSX.Element;
}

export const CurrentProvider = ({ children, loader }: Props) => {
  const api = useApi();
  const [userAndTenant, setUserAndTenant] = useState<CurrentAuth>();
  const [roles, setRoles] = useState<RoleValue[]>();
  const [currentParticipant, setCurrentParticipant] =
    useState<CurrentParticipant>();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToLogin = useCallback(() => {
    navigate(
      `${Paths.login}?redirect=${encodeURIComponent(
        `${location.pathname}${location.search}${location.hash}`
      )}`,
      {
        replace: true,
      }
    );
  }, [location.hash, location.pathname, location.search, navigate]);

  useEffect(() => {
    if (!userAndTenant) {
      api.auth
        .tryGetCurrent()
        .then(setUserAndTenant)
        .catch((e) => {
          if (e instanceof ApiError && e.status === 401) {
            navigateToLogin();
          } else {
            throw e;
          }
        });
    }

    if (userAndTenant && !roles) {
      api.roles.getCurrentRoles().then(setRoles);
    }

    if (userAndTenant && !currentParticipant) {
      api.participants.getCurrentParticipant().then(setCurrentParticipant);
    }
  }, [
    api,
    navigate,
    userAndTenant,
    roles,
    currentParticipant,
    navigateToLogin,
  ]);

  if (!userAndTenant || !roles || !currentParticipant) {
    return loader;
  }

  return (
    <CurrentContext.Provider
      value={{
        current: {
          ...userAndTenant,
          ...currentParticipant,
          roles,
        },
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
};

export function useCurrent(): Current | undefined {
  const data = useContext(CurrentContext);
  if (!data) {
    throw new Error('Cannot call useUser from outside a UserContext provider.');
  }
  return data.current;
}

export function useAssertCurrent(): Current {
  const current = useCurrent();
  if (!current) {
    throw new Error('User is not logged in.');
  }
  return current;
}
