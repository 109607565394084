import { useCallback, useState } from 'react';
import { Input } from '../../components/form/Input';
import styles from './AuthPage.module.scss';
import { useApi } from '../../hooks/useApi';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '../../router';
import { Register } from '../../services/AuthService';
import { setFormField, useFormValue } from '../../hooks/useFormValue';
import { Form, FormField } from '../../components/form/Form';
import { Stepper } from '../../components/Stepper';
import { Checkbox } from '../../components/form/Checkbox';

export const RegisterPage = () => {
  const [registration, setField] = useFormValue<Register>({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    totalShares: 0,
    certificatesPerShare: 0,
    termsAgreed: false,
  });
  const api = useApi();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const submit = useCallback(async () => {
    if (registration.password !== registration.confirmPassword) {
      throw new Error('Je wachtwoorden komen niet overeen.');
    }

    if (!registration.termsAgreed) {
      throw new Error(
        'Je moet akkoord gaan met de algemene voorwaarden en het privacybeleid.'
      );
    }

    await api.auth.register(registration);
    navigate('/');
  }, [api.auth, navigate, registration]);

  return (
    <div className={styles.authPage}>
      <div className={styles.modal}>
        <h1>Start met ParticiPro</h1>
        <p>
          Probeer 30 dagen lang alles van ParticiPro. Geen beperkingen,
          verplichtingen, of opstartkosten.
        </p>

        <Stepper
          step={step}
          steps={[
            {
              icon: 'building',
            },
            {
              icon: 'user',
            },
            {
              icon: 'rocket',
            },
          ]}
        />

        {step === 0 ? (
          <CompanyForm
            registration={registration}
            setField={setField}
            onSubmit={() => setStep(1)}
            onAltAction={() => undefined}
          />
        ) : (
          <UserForm
            registration={registration}
            setField={setField}
            onSubmit={submit}
            onAltAction={() => setStep(0)}
          />
        )}
        <div className={styles.bottomText}>
          Heb je al een account? <Link to={Paths.login}>Log in</Link>
        </div>
      </div>
    </div>
  );
};

interface FormPartProps {
  registration: Register;
  setField: setFormField<Register>;
  onSubmit: () => void;
  onAltAction: () => void;
}

const CompanyForm = ({
  registration,
  setField,
  onSubmit,
  onAltAction,
}: FormPartProps) => (
  <Form
    onSubmit={onSubmit}
    submitLabel="Volgende stap"
    onAltAction={onAltAction}
    fullWidth
  >
    <FormField label="Bedrijfsnaam">
      <Input
        type="text"
        value={registration.companyName}
        onChange={setField('companyName')}
        required
      />
    </FormField>
    <FormField label="Totaal aantal aandelen">
      <Input
        type="number"
        min={0}
        value={registration.totalShares}
        onChange={setField('totalShares')}
        placeholder="120"
      />
    </FormField>
    <FormField label="Certificaten per aandeel">
      <Input
        type="number"
        min={0}
        value={registration.certificatesPerShare}
        onChange={setField('certificatesPerShare')}
        placeholder="1000"
      />
    </FormField>
    <div>Geen zorgen: al deze informatie kan je later nog aanpassen.</div>
  </Form>
);

const UserForm = ({
  registration,
  setField,
  onSubmit,
  onAltAction,
}: FormPartProps) => (
  <Form
    onSubmit={onSubmit}
    submitLabel="Account aanmaken"
    altActionLabel="Vorige stap"
    onAltAction={onAltAction}
    fullWidth
  >
    <FormField label="Naam">
      <Input
        type="text"
        value={registration.userName}
        onChange={setField('userName')}
        required
      />
    </FormField>
    <FormField label="E-mailadres">
      <Input
        type="email"
        value={registration.email}
        onChange={setField('email')}
        required
      />
    </FormField>
    <FormField label="Wachtwoord">
      <Input
        type="password"
        value={registration.password}
        onChange={setField('password')}
        required
      />
    </FormField>
    <FormField label="Herhaal wachtwoord">
      <Input
        type="password"
        value={registration.confirmPassword}
        onChange={setField('confirmPassword')}
        required
      />
    </FormField>
    <FormField label="">
      <Checkbox
        checked={registration.termsAgreed}
        onChange={setField('termsAgreed')}
        required
      >
        <span>
          Ik ga akkoord met de{' '}
          <a
            href="https://www.participro.nl/voorwaarden"
            target="_blank"
            rel="noreferrer"
          >
            Algemene voorwaarden
          </a>{' '}
          en het{' '}
          <a
            href="https://www.participro.nl/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacybeleid
          </a>
          .
        </span>
      </Checkbox>
    </FormField>
  </Form>
);
